.board {
  display: grid;
  grid-template-columns: repeat(3, minmax(calc((100% - 2 * var(--gap-size)) / 3), max-content));
  max-width: 36em;
  margin: auto;

  background-color: black;
  --gap-size: clamp(2px, 1vmin, 6px);
  gap: var(--gap-size);

  --box-shadow-size: clamp(2px, 1vmin, 10px);

  /* prevent lines on the right and bottom at certain browser widths */
  box-shadow: inset calc(-1 * var(--box-shadow-size)) calc(-1 * var(--box-shadow-size))
      var(--body-color),
    inset var(--box-shadow-size) var(--box-shadow-size) var(--body-color);
}
