@font-face {
  font-family: 'Varela Round';
  src: local('Varela Round'),
    url('../../fonts/Varela_Round/VarelaRound-Regular-simplified.woff2') format('woff2'),
    url('../../fonts/Varela_Round/VarelaRound-Regular-simplified.woff') format('woff'),
    url('../../fonts/Varela_Round/VarelaRound-Regular-simplified.ttf') format('truetype');

  font-weight: bold;
}

.cell {
  --select-hover-opacity: 0.4;
  --text-color: black;
  --sheen-color: white;
  all: unset;
  background-color: var(--body-color);
  padding-top: 100%;
  position: relative;
  font-family: 'Varela Round', sans-serif;
  cursor: pointer;
}

.cell .token,
.cell::after {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(1em, 22vmin, 10em);
}

.cell::after {
  content: attr(data-current-token);
  visibility: hidden;
  opacity: var(--select-hover-opacity);
}

.cell:not(.noFocus):focus::after,
.cell.empty:hover::after {
  visibility: visible;
}

.cell.shiftToken:not(.noFocus):focus::after {
  transform: translate(-5%, -5%);
}

.cell.win .token {
  background: var(--text-color)
    linear-gradient(90deg, var(--text-color) 45%, var(--sheen-color), var(--text-color) 55%)
    no-repeat;
  background-size: 1ch;
  background-position: -1ch;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.cell.win .token {
  animation: sheen 4s linear infinite;
}

.cell.win:not(.onlyColWin).col2 .token {
  animation-delay: 0.8s;
}

.cell.win:not(.onlyColWin).col3 .token {
  animation-delay: 1.6s;
}

@keyframes sheen {
  0% {
    background-position: -1ch;
  }
  100% {
    background-position: 3ch;
  }
}
