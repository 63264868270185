* {
  box-sizing: border-box;
}

body {
  --body-color: #fffbc4;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  background-color: var(--body-color);
}
