@font-face {
  font-family: 'Gowun Dodum';
  src: local('Gowun Dodum'),
    url('../fonts/Gowun_Dodum/GowunDodum-Regular-simplified.woff2') format('woff2'),
    url('../fonts/Gowun_Dodum/GowunDodum-Regular-simplified.woff') format('woff'),
    url('../fonts/Gowun_Dodum/GowunDodum-Regular-simplified.ttf') format('truetype');

  font-weight: bold;
}

.toe {
  font-family: 'Gowun Dodum', sans-serif;
}

.controls {
  margin-top: 1em;
  text-align: center;
}

.controls .status {
  font-size: 1.5em;
}

.controls .newGame {
  font-size: 1.5em;
  padding: 0.5em 0.75em;
  border-radius: 1.5em;
  background-color: #27e;
  color: white;
  cursor: pointer;
}
